.dialog-contract-termination .dialog-title {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
  margin-block: var(--space-20);
}
.dialog-contract-termination .dialog-footer {
  display: flex;
  flex-direction: column;
  margin-block: var(--space-24);
  gap: var(--space-24);
}
.dialog-contract-termination .dialog-body {
  padding-inline: 5%;
}
.dialog-contract-termination .dialog-img {
  display: inline-block;
}